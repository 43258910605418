<template>
  <div>
    <p-inline-message v-if="notice !== undefined" :severity="notice.severity">
      {{ notice.message }}
    </p-inline-message>

    <p-dropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :showClear="true"
      :inputId="props.column.fieldInfo.name"
      :modelValue="props.filter.value"
      :filter="false"
      :editable="false"
      :class="['w-full']"
      :inputClass="['form-select', 'sm:mt-2', 'sm:mr-2']"
      v-on="eventListeners"
    >
    </p-dropdown>
  </div>
</template>

<script setup lang="ts">
interface _VTI_TYPE_DataTableFilterMetaData {
    /**
     * Filter value
     */
    value: any;
    /**
     * Filter match mode
     */
    matchMode: 'startsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'dateIs' | 'dateIsNot' | 'dateBefore' | 'dateAfter' | string | undefined;
}
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}
interface ColumnFilterProps {
  collectionName: string;
  column: _VTI_TYPE_Column;
  filter: _VTI_TYPE_DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}
  import { useI18n } from '#i18n'
  import { computed, shallowRef } from 'vue'
  import PDropdown, { DropdownChangeEvent } from 'primevue/dropdown'
  import PInlineMessage from 'primevue/inlinemessage'
  import { logger } from '~/service/logger/logger'
  import { NoticeMessage, NoticeSeverityEnum } from '~/service/notice/types'
  import { defineNoticeMessage } from '~/service/notice/notices'
  
  import { normalizeTranslationString } from '~/shared/lib/string'
  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();
  const { t } = useI18n();
  const notice = shallowRef<NoticeMessage | undefined>(undefined);
  const options = computed(() => {
    if (!("choices" in props.column.fieldInfo.meta.options)) {
      const errorLogMessage: string = `Not found required choices key in options. Returns array.`;
      const errorMessage: string = t("field_select_error_incorrect_choices");
      logger().error(
        {
          fieldName: props.column.fieldInfo.name,
        },
        errorLogMessage,
      );
      notice.value = defineNoticeMessage({
        severity: NoticeSeverityEnum.ERROR,
        message: errorMessage,
      });
      return [];
    }
    return (
      props.column.fieldInfo.meta.options.choices?.map((choice) => ({
        value: choice.value,
        label: normalizeTranslationString(choice.text),
      })) ?? []
    );
  });
  const eventListeners = {
    change: (event: DropdownChangeEvent) => {
      emit("update:filter", {
        filterName: props.column.name,
        data: event.value,
        immediate: true,
      });
    },
  };
</script>

<style scoped></style>

